import useLastCtaClicked from '~/hooks/analytics/useLastCtaClicked'

export interface TrackLinkData {
  ctaTextSnakeCase: string //the text/name of the CTA
}

export default function useTrackCtaClick() {
  const [, setlastCtaClicked] = useLastCtaClicked()
  const onClick = ({ ctaTextSnakeCase }: TrackLinkData) => {
    // set the CTA name to the Analytics for tracking in the following page
    setlastCtaClicked(ctaTextSnakeCase)
  }
  return onClick
}
