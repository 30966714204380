import { useMemo } from 'react'

import useTranslation from 'next-translate/useTranslation'

import { BASE_COUNT_INTL_WEB_GAMES, BASE_COUNT_EN_WEB_GAMES } from '~/constants'

const useLocale = (navLanguage = navigator?.language) => {
  const { lang } = useTranslation()

  // Check if navLanguage is a supported language
  const isSupported = useMemo(() => /^en|fr|pt|de|es|ja|ko/.test(navLanguage), [navLanguage])

  // If the user's browser is on a supported language and that's the language the site is set to,
  // use the browser language which includes the region, otherwise just use the language the site is set to
  const locale: string = isSupported && navLanguage.startsWith(lang) ? navLanguage : lang

  // To set the site language when getting redirected from LumAuth
  // we need to get the first 2 characters of the browser language, if it's a language we support, otherwise default to English
  const browserLanguagePrefix = isSupported ? navLanguage.slice(0, 2) : 'en'
  const nonEnglishLanguagePrefix = browserLanguagePrefix === 'en' ? '' : browserLanguagePrefix

  // If using a supported language other than English, use the intl web count, otherwise use the EN game count.
  const minNumberOfGamesForLocale =
    nonEnglishLanguagePrefix.length > 0 ? BASE_COUNT_INTL_WEB_GAMES : BASE_COUNT_EN_WEB_GAMES

  return { locale, nonEnglishLanguagePrefix, minNumberOfGamesForLocale }
}

export default useLocale
