import {
  BodyText1,
  BodyText2,
  BodyText2SemiBold,
  MicroText2,
  Subheading1,
  Subheading2,
} from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

import ButtonWithTracking from '~/components/ui/ButtonWithTracking'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import Chevron from '~/images/chevron_right.svg'

export const GameCardAura = styled.div`
  padding: 8px;
  position: relative;
`

export const AreaLabel = styled(MicroText2)`
  color: ${(props) => props.theme.colors.neutral[200]};
`

export const CardShadowRadius = styled.div`
  background: ${(props) => props.theme.colors.background?.card};
  box-shadow: ${(props) => props.theme.effects.boxShadow.outset[2]};
  border-radius: 16px;
`

export const CardNoShadow = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral[100]};
  background: ${(props) => props.theme.colors.background?.card};
  border-radius: 16px;
  padding: 24px;
`

export const ExitButtonContainer = styled.div`
  padding-left: 40px;
  > a {
    margin-top: 128px;
    min-height: 0;
    min-width: 0;
    box-sizing: content-box;
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.tablet} {
    a {
      > span:first-child {
        padding: 0;
        > svg {
          padding: 0;
          text-align: center;
        }
      }
      > span:nth-child(2) {
        display: none;
      }
    }
  }
`

export const UnlockBadge = styled.div`
  width: 64px;
`

export const BackButtonBadge = styled(Chevron)`
  font-size: 19px;
  transform: rotate(180deg);
  padding-left: 20px;
`

export const Error = () => {
  const t = useTranslationForNamespace('common')
  return (
    <BodyText1 as='h1'>
      {/* Include fallback text error about being offline in English as translations don't work offline */}
      {t(
        'errorMessages.miscReloadError',
        {},
        {
          fallback: "Looks like you're offline! Please check your internet connection and try again.",
        },
      )}
    </BodyText1>
  )
}

export const SettingsCardHeaders = styled(Subheading2)`
  color: ${({ theme }) => theme.colors.neutral[300]};
`

export const SettingsCardBodyHeader = styled(BodyText2SemiBold)`
  color: ${({ theme }) => theme.colors.neutral[300]};
`

export const SettingsCardBody = styled(BodyText2)`
  color: ${({ theme }) => theme.colors.neutral[300]};
`

export const OrangeButtonLink = styled(ButtonWithTracking).attrs({ kind: 'clean' })`
  color: ${({ theme }) => theme.colors.system.primaryAccent};
  text-transform: uppercase;
  min-height: 20px;
  height: 20px;
  &:disabled {
    color: ${({ theme }) => theme.colors.neutral[300]};
  }
  span {
    font-weight: 600;
    font-size: 14px;
    &:hover {
      font-weight: 800;
    }
  }
`

export const CenteredFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
`

export const SettingsInfoCard = styled(CardNoShadow)`
  margin: 0 20px 40px;
`

export const CardLabel = styled(Subheading1)`
  margin: 0 20px 20px;
`
