import React from 'react'

import { Button } from '@lumoslabs/lumosity-storybook'
import { ButtonKinds, JustifyContent } from '@lumoslabs/lumosity-storybook/build/Button/Button'
import { snakeCase } from 'lodash'

import useTrackCtaClick from '~/hooks/analytics/useTrackCtaClick'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

export interface ButtonWithTrackingProps {
  onClick?: () => void
  disabled?: boolean
  Icon?: SvgImage
  kind?: keyof typeof ButtonKinds
  wide?: boolean
  leftBadge?: JSX.Element
  rightBadge?: JSX.Element
  as?: React.ElementType<any>
  shadow?: boolean
  justifyContent?: keyof typeof JustifyContent
  textKey?: string
  ctaTextSnakeCase?: string
  children?: React.ReactNode
  className?: string
}

const ButtonWithTracking = React.forwardRef<HTMLButtonElement, ButtonWithTrackingProps>(
  (
    {
      onClick,
      disabled = false,
      Icon,
      kind,
      wide,
      leftBadge,
      rightBadge,
      as,
      shadow,
      justifyContent = 'center',
      textKey,
      ctaTextSnakeCase,
      children,
      className,
    },
    ref,
  ) => {
    const buttonProps = {
      onClick,
      textKey,
      disabled,
      ctaTextSnakeCase,
      Icon,
      kind,
      wide,
      rightBadge,
      leftBadge,
      as,
      shadow,
      justifyContent,
      className,
    }

    Object.assign(buttonProps, onClick ? { onClick: onClick } : {})

    const t = useTranslationForNamespace('common')
    const trackCta = useTrackCtaClick()

    // Default to "Next" if no textKey is specified and the button doesn't have children/text
    const ctaTextKey = textKey || 'next'
    // Use the provided ctaTextSnakeCase, or create it out of the children text, or create it out of the ctaTextKey
    const ctaTextForTracking = ctaTextSnakeCase || (children && snakeCase(String(children))) || snakeCase(ctaTextKey)

    // Use the provided textKey to get the translation, but if the translation is not found, use "Next"
    const buttonText = t(
      `buttons.${textKey ?? 'next'}`,
      { default: t('buttons.next') },
      { fallback: t('buttons.next') },
    )

    // add tracking to the click handler to track CTA in Analytics Context
    const onClickWithTrack: typeof onClick = (...args) => {
      trackCta({
        ctaTextSnakeCase: ctaTextForTracking,
      })
      return onClick ? onClick(...args) : undefined
    }

    if (children) {
      return (
        <Button ref={ref} {...buttonProps} onClick={onClickWithTrack}>
          {children}
        </Button>
      )
    }

    return (
      <Button {...buttonProps} onClick={onClickWithTrack}>
        {buttonText}
      </Button>
    )
  },
)

export default ButtonWithTracking
