import { NetworkError } from '@apollo/client/errors'
import { GraphQLError } from 'graphql'

export const logToRollbar = (err: string | GraphQLError | NetworkError) => {
  if (typeof window !== 'undefined') {
    if (process.env.NODE_ENV !== 'development' && err !== null) {
      window?.Rollbar?.error?.(err, (rollbarError) => {
        if (rollbarError) {
          console.error('Rollbar error reporting failed:')
          console.error(rollbarError)
          return
        }
        console.log('Reported error to Rollbar')
      })
    }
  }
}
